import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './utils'
import './assets/css/index.scss'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'

Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
	router,
  render: h => h(App),
}).$mount('#app')
