import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '首页',
        component: () => import('@/views/Index'),
        redirect: '/Index',
        children: [
            {
                path: '/index',
                name: '首页',
                component: () => import('@/views/category/Index.vue')
            },
            {
                path: '/detail',
                name: '二级目录',
                component: () => import('@/views/category/Detail')
            },
            {
                path: '/list',
                name: '所有项目',
                component: () => import('@/views/list/Index.vue')
            },
        ]
    },
    {
        path: '/login',
        name: '登录',
        component: () => import('@/views/login/Index')
    }
]

const router = new VueRouter({
    routes
})

export default router;

